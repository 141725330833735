.listWrapper {
	display: flex;
	flex-direction: column;
	padding: 24px;
	background: rgba(161, 211, 255, 0.14);
	border-radius: 8px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border: 1px solid rgba(161, 211, 255, 0.3);
	gap: 16px;
}

.loadingWrapper {
	min-height: 420px;
}

.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	z-index: 90;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: rgba(183, 183, 183, 0.31);
	border-radius: 8px;
	backdrop-filter: blur(1px);
	-webkit-backdrop-filter: blur(1px);
	border: 1px solid rgba(183, 183, 183, 0.3);

	div {
		color: #42a6ff !important;
	}
}

.treeItem {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.codeName {
	font-size: 17px;
	font-weight: 700;
	margin: 0 0 8px;
}